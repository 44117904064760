import { Button, FormControl, FormLabel, Heading, Input, useBoolean } from "@chakra-ui/react";
import { DeepErrorBox } from "../../components/misc";
import { RemoteButton } from "../../components/RemoteButton";
import { useState } from "react";
import { useDeepApi, useDeepAuth } from "../../components/utils";
import { SignInRequired } from "../auth/DeepAuth";
import { ICityAffairCreateApi } from "@dvapi/affair.int";
import { useNavigate } from "react-router-dom";




export function AffairCreate() {

    const api = useDeepApi();
    const authCtx = useDeepAuth();
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useBoolean();
    const [error, setError] = useState('');

    const doAffairCreate = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            affairTitle: { value: string };
        };
        const affairTitle = target.affairTitle.value;
        setIsUploading.on();
        setError('');
        try {
            const resp = await api.fetch<ICityAffairCreateApi>({
                url: '/city/:cityKey/affair',
                method: 'POST',
                payload: {
                    affairTitle
                },
                params: {
                    cityKey: 'hu~~szentendre'
                }
            });
            navigate(`/affair/${resp.affair.affairKey}`)
        } catch (ex) {
            setError('' + ex);
        } finally {
            setIsUploading.off();
        }
    }

    if (!authCtx.user) {
        return <SignInRequired />
    }

    return <form onSubmit={doAffairCreate}>
        <fieldset disabled={isUploading}>
            <Heading>Ügy bejelentése</Heading>
            <DeepErrorBox error={error} />
            <FormControl>
                <FormLabel>Cím</FormLabel>
                <Input type='text' name='affairTitle' />
            </FormControl>
            <FormControl>
                <RemoteButton isLoading={isUploading} type='submit'>Bejelentem</RemoteButton>
            </FormControl>
        </fieldset>
    </form>
}