import { extendTheme, theme as defaultTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

export const TOKENS = {
    BG : 'bg',
    BRAND: 'brand',
    BRAND_HOVER: 'brand_hover',
}


// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    [TOKENS.BRAND]: defaultTheme.colors.yellow,
    [TOKENS.BRAND_HOVER]: defaultTheme.colors.yellow,
    [TOKENS.BG]: '#d2ccc3',
    "black" : {
        50: '#000',
        100: '#000',
        200: '#000',
        300: '#000',
        400: '#000',
        500: '#000',
        600: '#000',
        700: '#000',
        800: '#000',
        900: '#000',
    }
}

export const deepTheme = extendTheme({
    colors,
    semanticTokens: {
        colors: {
            [TOKENS.BRAND]: {
                default: "brand.300",
                _dark: "brand.200",
            },
            [TOKENS.BRAND_HOVER]: {
                default: "brand.400",
                _dark: "brand.300",
            }
        }
    },
    styles: {
		global: (props: StyleFunctionProps) => ({
            body: {
                bg: mode('gray.100', 'gray.800')(props),
			},
            '.hover-bg:hover': {
                bg: mode('gray.100', 'gray.800')(props),
            },
            '.hover-bg.selected': {
                bg: mode('gray.200', 'gray.700')(props),
            },
		})
	}
});