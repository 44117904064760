import { Button, FormControl, FormLabel, Input, ModalBody, ModalContent, ModalFooter, ModalHeader, useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import { DeepErrorBox } from "../../components/misc";
import { RemoteButton } from "../../components/RemoteButton";
import { ISignInInfo, ISignUpApi } from "@dvapi/auth.int";
import { useDeepApi, useDeepAuth } from "../../components/utils";

export interface signUpProps {
    onSignIn: () => void;
    onClose: () => void;
}

export function SignUp({ onSignIn, onClose }: signUpProps) {

    const api = useDeepApi();
    const authCtx = useDeepAuth();

    const [isLoading, setIsLoading] = useBoolean();
    const [error, setError] = useState('');

    const usrSubmitSignUp = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            userName: { value: string };
            email: { value: string };
            password: { value: string };
            passwordAgain: { value: string };
        };
        const userName = target.userName.value;
        const email = target.email.value;
        const password = target.password.value;
        const passwordAgain = target.passwordAgain.value;
        if (password != passwordAgain) {
            setError('Nem stimmel a két jelszó!');
            return;
        }
        setIsLoading.on();
        setError('');
        try {
            ///debugger;
            const resp = await api.fetch<ISignUpApi>({
                url: '/auth/signup',
                method: 'POST',
                payload: {
                    userName, email, password
                }
            });
            authCtx.onSignIn(resp);
            onClose();
        } catch (ex) {
            setError('' + ex);
        } finally {
            setIsLoading.off();
        }
    }


    return <form onSubmit={usrSubmitSignUp}>

        <ModalContent>
            <ModalHeader>Regisztráció</ModalHeader>
            <ModalBody>
                <DeepErrorBox error={error} />
                <fieldset disabled={isLoading}>
                    <FormControl>
                        <FormLabel>Felhasználó név</FormLabel>
                        <Input type='text' autoComplete="username" name='userName' />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email cím</FormLabel>
                        <Input type='email' autoComplete="username" name='email' />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Jelszó</FormLabel>
                        <Input type='password' autoComplete="new-password" name='password' />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Jelszó még egyszer</FormLabel>
                        <Input type='password' autoComplete="new-password" name='passwordAgain' />
                    </FormControl>
                </fieldset>
            </ModalBody>

            <ModalFooter>
                <Button onClick={onSignIn} variant='ghost'>Bejelentkezés</Button>
                <RemoteButton type='submit' isLoading={isLoading} colorScheme='blue' ml={3} >
                    Regisztrálok
                </RemoteButton>
            </ModalFooter>
        </ModalContent>
    </form>
}