import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Heading,
	Text,
	VStack
} from "@chakra-ui/react";
import { IAffair, IAffairPub, ICityAffairListGet } from "@dvapi/affair.int";
import { useEffect, useState } from "react";
import { useDeepApi } from "../../components/utils";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import { DeepProgress } from "../../components/misc";
import { AvatarBox } from "../avatar/AvatarBox";
import { TextListRef } from "../textlist/TextListRef";


export function Dashboard() {

	const api = useDeepApi();


	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [affairs, setAffairs] = useState<IAffairPub[]>([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const resp = await api.fetch<ICityAffairListGet>({
					url : '/city/:cityKey/affair', method: "GET", params: { cityKey : 'hu~~szentendre' }
				});
				setAffairs(resp.affairs);
			} catch (ex) {
				setError('' + ex);
			} finally {
				setIsLoading(false);
			}
		})();
	}, []);


	return <Box className='dv-dashboard-outer'>
		<DeepProgress isLoading={isLoading} />
		<VStack alignItems='flex-start'>

			{error && <Alert status="error">
				<AlertIcon /><AlertDescription>{error}</AlertDescription>
			</Alert>}
			<Heading size='lg'>Szentendre</Heading>
			{(affairs || []).map(affair => <ChakraLink key={affair.affairKey} as={ReactRouterLink} to={`/affair/${affair.affairKey}`}>
				<Card p={3}>
					<CardHeader p={2} pb={0} textAlign='left' >
						<Heading as='h2' size='md' >{affair.affairTitle}</Heading>
					</CardHeader>
					<CardBody p={2}>
						<TextListRef textListRef={affair.briefRef} />
					</CardBody>
					<AvatarBox parent={affair} variant="mini"/>

				</Card>
			</ChakraLink>)}
			<Button as={ReactRouterLink}  to='/affair-create' >Ügy bejelentése</Button>
		</VStack>
	</Box>
}
