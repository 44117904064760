import { Alert, AlertDescription, AlertIcon, Box, FormErrorMessage, Heading, Progress } from "@chakra-ui/react";
import { MutableRefObject, ReactNode, createContext } from "react";

export interface IDeepLayoutCtx {
	editorElem : HTMLElement|null;
}

export const DeepLayoutCtx = createContext<IDeepLayoutCtx>({
	editorElem: null
})


export interface DeepProgressProps {
	isLoading: boolean;
}

export function DeepProgress({ isLoading }: DeepProgressProps) {
	return <>
		{isLoading && <Progress isIndeterminate height='3px' colorScheme="gray" />}
	</>
}


export interface DeepErrorBoxProps {
	error: unknown;
}

export function DeepErrorBox({ error }: DeepErrorBoxProps) {
	if (!error)
		return <></>
	return <Alert status="error">
		<AlertIcon /><AlertDescription>{'' + error}</AlertDescription>
	</Alert>
}

export const DEEP_LINE_ERROR = 'Min 3 max 512 karakter!';
export function deepLineCheck(text : string) {
	return !!text && typeof text === 'string' && text.length >= 3 && text.length <= 512;
}


export interface SubHeadProps {
	title : ReactNode;
	tools : ReactNode;
}

export function SubHead({title, tools} : SubHeadProps) {
	return <Box w='100%' display='flex' flexDir='row' justifyContent='space-between' alignItems='center'>
		<Heading size='sm' >{title}</Heading>
		<Box>
			{tools}
		</Box>
	</Box>
}

export function DeepNumber({value}: {value: number}) {
	return <Box display='inline-block' 
		bg='#ffe400' borderRadius='md' border='1px solid #8888' px='4px'
		fontWeight='bold'
		fontSize='sm'
	>{value}</Box>
}