import { Box, Button, Divider, FormControl, FormErrorMessage, FormLabel, IconButton, IconButtonProps, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Textarea, UnorderedList, useBoolean, useToast } from "@chakra-ui/react";
import { ITextListChange, ITextListChangePart, ITextListChangePart_LI_DEL, ITextListChangePart_LI_INS, ITextListChangePart_TX_MOD, ITextListChangePost, ITextListItemPub, ITextListRefPub } from "@dvapi/textlist.int";
import { useEffect, useState } from "react";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { RemoteButton } from "../../components/RemoteButton";
import { DEEP_LINE_ERROR, DeepErrorBox, deepLineCheck } from "../../components/misc";
import { generateKey, useDeepApi } from "../../components/utils";


interface IChangedPoint {
	txKey: string;
	liKey: string;
	orig?: ITextListItemPub;
	text: string;
	isEdited: boolean;
	isRemoved: boolean;
}


// -------------------------------------------------------------------------
function ToolButton(props: IconButtonProps) {
	return <IconButton {...props} variant='ghost' p={0} />
}


// -------------------------------------------------------------------------

export interface TextListEditProps {
	origTextList: ITextListRefPub;
	onChangeCreated: (newTextList: ITextListRefPub) => void;
	onClose: () => void;
}


// -------------------------------------------------------------------------
export function TextListEdit({ origTextList, onChangeCreated, onClose }: TextListEditProps) {

	const api = useDeepApi();
	const toast = useToast();

	const [changes, setChanges] = useState<IChangedPoint[]>([]);


	const [partsToSubmit, setPartsToSubmit] = useState<ITextListChangePart[]>([]);
	const [showSubmit, setShowSubmit] = useBoolean();
	const [isUploading, setIsUploading] = useBoolean();
	const [submitError, setSubmitError] = useState('');
	const [reasonText, setReasonText] = useState('');

	useEffect(() => {
		setChanges((origTextList?.items || []).map(orig => ({
			txKey: orig.txKey,
			liKey: orig.liKey,
			orig,
			isRemoved: false,
			isEdited: false,
			text: orig.text

		})));
	}, [origTextList]);

	const setChange = (changedPix: number, fn: (point: IChangedPoint) => IChangedPoint) => {
		setChanges(changes.map((point, pix) => (
			pix !== changedPix ? point : fn(point)
		)))
	}

	const pointEdited = (pix: number) => {
		setChange(pix, (point) => ({ ...point, isEdited: true }));
	}

	const textChanged = (pix: number, newText: string) => {
		setChange(pix, (point) => ({ ...point, text: newText }));
	}

	const pointRemoved = (pix: number) => {
		const point = changes[pix];
		if (!point.orig) {
			changes.splice(pix, 1);
			setChanges([...changes]);
		} else {
			setChange(pix, (point) => ({ ...point, isRemoved: !point.isRemoved, isEdited: false }));
		}


	}

	const pointAdd = (pix: number, isAfter: boolean = true) => {
		changes.splice(pix + 1, 0, {
			liKey: 'li' + generateKey(),
			txKey: 'tx' + generateKey(),
			isRemoved: false,
			isEdited: true,
			text: '',
		})
		setChanges([...changes])
	}

	// ----------------
	const doShowSubmit = async () => {
		setPartsToSubmit([]);
		const parts: ITextListChangePart[] = [];
		let prevPoint: IChangedPoint | null = null;
		for (let point of changes) {
			const isChanged = point.orig && (point.text !== point.orig.text);

			if (point.orig && point.isRemoved) {
				const part: ITextListChangePart_LI_DEL = {
					action: 'LI-DEL',
					liKey: point.orig.liKey,
				}
				parts.push(part);
			}

			if (!point.orig && !!point.text) {
				const partTx: ITextListChangePart_TX_MOD = {
					action: 'TX-MOD',
					txKey: point.txKey,
					text: point.text,
				}
				parts.push(partTx);
				const partLi: ITextListChangePart_LI_INS = {
					action: 'LI-INS',
					liKey: point.liKey,
					txKey: point.txKey,
					afterLiKey: prevPoint?.liKey
				}
				parts.push(partLi);
			}

			if (isChanged && !point.isRemoved) {
				const partTx: ITextListChangePart_TX_MOD = {
					action: 'TX-MOD',
					txKey: point.txKey,
					text: point.text,
				}
				parts.push(partTx);
			}


			prevPoint = point;
		}

		if (parts.length > 0) {
			console.log(JSON.stringify(parts, undefined, 2));
			setPartsToSubmit(parts);
			setShowSubmit.on();
		} else {
			toast({
				status: 'warning',
				description: 'Írj be valami változtatást!'
			})
		}
	}

	const doSubmit = async () => {
		const payload: ITextListChange = {
			partList: partsToSubmit,
			reasonText,
			startHistoryIx: origTextList.historyIx,
		}
		setSubmitError('');
		setIsUploading.on();
		try {
			const resp = await api.fetch<ITextListChangePost>({
				url: '/textlist/:textListKey/change',
				method: 'POST',
				params: {
					textListKey: origTextList.textListKey
				},
				payload: {
					change: payload
				}
			});
			onChangeCreated(resp.textListRef);
		} catch (ex) {
			setSubmitError(''+ex);
		} finally {
			setIsUploading.off();
		}

	}


	if (!origTextList)
		return <></>;

	return <form>
		<fieldset disabled={isUploading}>

			<Modal isOpen={showSubmit} onClose={() => setShowSubmit.off()} >
				<ModalOverlay />
				<ModalCloseButton />
				<ModalContent>
					<DeepErrorBox error={submitError} />
					<ModalBody>
						<FormControl isInvalid={!deepLineCheck(reasonText)}>
							<FormLabel>Módosítás indoklása:</FormLabel>
							<Input value={reasonText} onChange={(e) => setReasonText(e.currentTarget.value)} />
							<FormErrorMessage>{DEEP_LINE_ERROR}</FormErrorMessage>
						</FormControl>

					</ModalBody>
					<ModalFooter>
						<RemoteButton isLoading={isUploading} colorScheme="blue" onClick={() => doSubmit()}
							isDisabled={!deepLineCheck(reasonText)}
						>Beküldöm</RemoteButton>

					</ModalFooter>
				</ModalContent>
			</Modal>
			<UnorderedList>
				{changes.map((point, pix) => <ListItem key={pix}

				>
					<Box display='flex' flexDir='row'>
						<Box flex='1' p={1}>
							<Box opacity={.5} fontSize='small'>{point.liKey} &nbsp;&nbsp; {point.txKey}</Box>
							{point.orig && <Box as='span'
								textDecoration={point.isEdited || point.isRemoved ? 'line-through' : 'none'}
							>{point.orig.text}</Box>}
							{point.isEdited &&
								<Textarea value={point.text} onChange={(e) => textChanged(pix, e.currentTarget.value)} />
							}
						</Box>
						<Box display='flex' flexDir='row' alignItems='center' justifyContent='space-evenly' p={1}>
							<ToolButton icon={<MdEdit />} aria-label="Szerkesztés" onClick={() => pointEdited(pix)} />
							<ToolButton icon={<MdDelete />} aria-label="Törlés" onClick={() => pointRemoved(pix)} />
							<ToolButton icon={<MdAdd />} aria-label="Új sor" onClick={() => pointAdd(pix)} />
						</Box>
					</Box>
				</ListItem>)}
			</UnorderedList>
			<Divider py={3} />
			<Box display='flex' justifyContent='flex-end' alignItems='center' p={2} gap={2}>
				<Button onClick={onClose}>Mégsem</Button>
				<Button  colorScheme="blue" onClick={doShowSubmit}>Beküldöm</Button>
			</Box>
		</fieldset>
	</form>

}