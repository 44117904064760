import { Box, Card, CardBody, CardHeader, Heading, useBoolean } from "@chakra-ui/react";
import { ITextListChangeAppendPost, ITextListChangeListGet, ITextListChangePart_LI_DEL, ITextListChangePart_LI_INS, ITextListChangePart_TX_MOD, ITextListChangePub, ITextListItem, ITextListItemPub, ITextListPub, ITextListRefPub } from "@dvapi/textlist.int";
import { useEffect, useState } from "react";
import { RemoteButton } from "../../components/RemoteButton";
import { DeepErrorBox, DeepProgress } from "../../components/misc";
import { useDeepApi, useDeepAuth } from "../../components/utils";


export interface ITextListItemShowChange extends ITextListItemPub {
	origText?: string;
	isModified?: boolean;
	isDeleted?: boolean;
}

export interface ITextListShowChange extends ITextListPub {
	items: ITextListItemShowChange[];
}

export interface TextListChangesProps {
	textListKey: string;
	onChangeSelect: (change: ITextListShowChange) => void;
	onChangeCreated: (newTextList: ITextListRefPub) => void;
	onClose: () => void;
}

export interface IResp { current: ITextListPub, changes: ITextListChangePub[] };

export function TextListChanges({ textListKey, onChangeSelect, onChangeCreated, onClose }: TextListChangesProps) {

	const api = useDeepApi();
	const authCtx = useDeepAuth();
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useBoolean(true);
	const [isUploading, setIsUploading] = useBoolean(false);
	const [changes, setChanges] = useState<ITextListChangePub[]>([]);
	const [original, setOriginal] = useState<ITextListPub>();

	const [selectedChange, setSelectedChange] = useState<ITextListChangePub>();
	const [current, setCurrent] = useState<ITextListShowChange>();


	useEffect(() => {
		setError('');
		if (!textListKey) {
			setChanges([]);
			return;
		}
		(async () => {
			setIsLoading.on();
			try {
				const resp = await api.fetch<ITextListChangeListGet>({
					url: '/textlist/:textListKey/change', method: 'GET', params: {
						textListKey
					}
				});
				setOriginal(resp.current);
				setChanges(resp.changes);
			} catch (ex) {
				setError('' + ex);
			} finally {
				setIsLoading.off();
			}
		})();

	}, [textListKey]);

	const showChange = (change: ITextListChangePub) => {
		setError('');
		try {
			setSelectedChange(change);
			const newList = JSON.parse(JSON.stringify(original)) as ITextListShowChange;
			const texts = new Map<string, ITextListItem>();
			for (let x of newList.items) {
				texts.set(x.txKey, { text: x.text });
			}
			for (let part of change.partList) {

				if (part.action === 'TX-MOD') {
					const p = part as ITextListChangePart_TX_MOD;
					texts.set(p.txKey, { text: p.text });
					newList.items.filter(i => i.txKey === p.txKey).forEach(item => {
						item.origText = item.origText || item.text;
						item.text = p.text;
						item.isModified = true;
					});

				}
				if (part.action === 'LI-INS') {
					const p = part as ITextListChangePart_LI_INS;
					const textItem = texts.get(p.txKey);
					if (!textItem)
						throw `Nincs textItem: ${p.txKey}`;
					const newItem: ITextListItemShowChange = {
						liKey: p.liKey, text: textItem.text, txKey: p.txKey,
						isModified: true
					};
					let lix = 0;
					if (p.afterLiKey) {
						lix = newList.items.findIndex(i => i.liKey === p.afterLiKey)
						if (lix < 0)
							throw `Nincs afterLiKey: ${p.afterLiKey}`;
						lix++;
					}
					newList.items.splice(lix, 0, newItem);
				}
				if (part.action === 'LI-DEL') {
					const p = part as ITextListChangePart_LI_DEL;
					const oldItem = newList.items.find(i => i.liKey === p.liKey);
					if (!oldItem)
						throw `Nincs LI-DEL/liKey: ${p.liKey}`;
					oldItem.isDeleted = true;
					oldItem.origText = oldItem.origText || oldItem.text;
					oldItem.text = '';

				}
			} // forEach partList
			setCurrent(newList);
			onChangeSelect(newList);
		} catch (ex) {
			setError('' + ex);
		}
	}

	const appendChange = async () => {
		if (!selectedChange)
			return;
		setIsUploading.on();
		try {
			const resp = await api.fetchToast<ITextListChangeAppendPost>({
				url: '/textlist/:textListKey/change/:changeKey/append', method: 'POST',
				params: {
					textListKey, changeKey: selectedChange.changeKey
				}
			});
			setOriginal(resp.current);
			setChanges(resp.changes);
			onChangeCreated(resp.current);
			if (resp.changes.length === 0)
				onClose();
		} catch (ex) {
		} finally {
			setIsUploading.off();
		}
	}

	return <Card>
		<CardHeader>
			<DeepProgress isLoading={isLoading} />
			<Heading size='md'>Változtatások:</Heading>
			<DeepErrorBox error={error} />
		</CardHeader>
		<CardBody>
			{changes.map(change => <Box
				key={change.changeKey}
				className={"hover-bg" + (selectedChange === change ? ' selected' : '')}
				p={2} cursor='pointer' borderRadius='md'
				onClick={() => showChange(change)}
			>
				<Box>{change.reasonText}</Box>
				{selectedChange === change && authCtx.user && <Box py={9} display='flex' flexDir='row' justifyContent='space-evenly' gap={2}>
					<RemoteButton isLoading={isUploading} colorScheme="red" >Nem</RemoteButton>
					<RemoteButton isLoading={isUploading} colorScheme="green" onClick={appendChange} >Mehet</RemoteButton>
				</Box>}
			</Box>)}

		</CardBody>
	</Card>
}