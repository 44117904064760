import { Box, Card, CardBody, CardHeader, Grid, Img, Stack } from "@chakra-ui/react";
import { IDocuPub } from "@dvapi/docu.int";
import { useState } from "react";
import { TextListRef } from "../textlist/TextListRef";
import { DocuCreate } from "./DocuCreate";
import { useDeepAuth } from "../../components/utils";


export interface DocuListProps {
	affairKey: string;
	initialList: IDocuPub[];
}

export function DocuList({ affairKey, initialList }: DocuListProps) {

	const auth = useDeepAuth();
	const [docuList, setDocuList] = useState(initialList);

	return <Grid className="docus" gap={2} templateColumns='repeat(2, 1fr)'  >
			{(docuList || []).map((docu, ix) => <Card 
				key={ix}
			>
				<CardHeader
					display='flex' flexDir='row' alignItems='flex-start'
				>
					<Img src={`https://uploads.deepvoter.com/${docu.fileName}`}
						width='150px' height='150px' overflow='hidden' objectFit='cover' />
				</CardHeader>
				<CardBody>
					<TextListRef textListRef={docu.briefRef} />
				</CardBody>
			</Card>)}
			
		</Grid>
	

}