import { Alert, AlertDescription, AlertIcon, Box, Card, CardBody, CardHeader, HStack, Heading, VStack, useBoolean } from "@chakra-ui/react";
import { IAffairGetApi, IAffairJoinDeleteApi, IAffairJoinPostApi, IAffairPub, TUserAffairRel } from "@dvapi/affair.int";
import { IDocuPub } from "@dvapi/docu.int";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { RemoteButton } from "../../components/RemoteButton";
import { DeepNumber, DeepProgress, SubHead } from "../../components/misc";
import { useDeepApi, useDeepEndpoint } from "../../components/utils";
import { AvatarBox } from "../avatar/AvatarBox";
import { DocuCreate } from "../docu/DocuCreate";
import { DocuList } from "../docu/DocuList";
import { TextListRef } from "../textlist/TextListRef";
import { DeepSubscribeNotifications } from "../../components/DeepSubscribeNotifications";




export function Affair() {

	const x = useParams();
	const affairKey = x.affairKey;
	const api = useDeepApi();

	const [affair, setAffair] = useState<IAffairPub>();
	const [docus, setDocus] = useState<IDocuPub[]>([]);
	const [visitorRel, setVisitorRel] = useState<{ rel: TUserAffairRel; createdAtUtcs: string; } | undefined>();
	const [isUploading, setIsUploading] = useBoolean();


	const state = useDeepEndpoint<IAffairGetApi>('/affair/:affairKey', { affairKey: affairKey || '' }, resp => {
		setAffair(resp.affair);
		setDocus(resp.docus);
		setVisitorRel(resp.visitorRel);
	});

	const joinClick = async () => {
		if (!affairKey)
			return;
		setIsUploading.on();
		try {
			const resp = await api.fetchToast<IAffairJoinPostApi>({
				url: "/affair/:affairKey/join",
				method: 'POST',
				params: { affairKey }
			});
			setAffair(resp.affair);
			// TODO
			setVisitorRel({
				rel: 'support', 'createdAtUtcs': new Date().toUTCString()
			});
		} catch (ex) {
		} finally {
			setIsUploading.off();
		}
	}

	const leaveClick = async () => {
		if (!affairKey)
			return;
		setIsUploading.on();
		try {
			const resp = await api.fetchToast<IAffairJoinDeleteApi>({
				url: "/affair/:affairKey/join",
				method: 'DELETE',
				params: { affairKey }
			});
			setAffair(resp.affair);
			// TODO
			setVisitorRel(undefined);
		} catch (ex) {
		} finally {
			setIsUploading.off();
		}
	}


	if (!affairKey)
		return <Box>No affair key</Box>;

	return <Box className='dv-layout-main' minWidth='10em' pb='160px' >
		<DeepProgress isLoading={state.isLoading} />

		{state.error && <Alert status="error">
			<AlertIcon /><AlertDescription>{state.error}</AlertDescription>
		</Alert>}

		{affair && <VStack alignItems='stretch' p={4}>

			<Card>
				<CardHeader>
					<Heading size='lg' >{affair.affairTitle}</Heading>
					<HStack gap={4} mt={2} opacity={.6}>
						<Box>Elkötelezettek: <DeepNumber value={affair.userCounts.engaged} /></Box>
						<Box>Aktivisták: <DeepNumber value={affair.userCounts.activist} /></Box>
						<Box>Támogatók: <DeepNumber value={affair.userCounts.support} /></Box>
						{!visitorRel
							? <RemoteButton onClick={joinClick} isLoading={isUploading} size='xs' colorScheme='yellow'  >Támogatom</RemoteButton>
							: <HStack>
								<Box>Csatlakoztál: {visitorRel.createdAtUtcs}</Box>
								<RemoteButton onClick={leaveClick} isLoading={isUploading} variant='ghost' size='xs' colorScheme='yellow' >Kilépek ({visitorRel.rel})</RemoteButton>
							</HStack>
						}
					</HStack>
					<DeepSubscribeNotifications topicRef={{kind: 'affair', key: affairKey}} />
				</CardHeader>
				<CardBody mt={0}>
					<TextListRef textListRef={affair.briefRef} />

				</CardBody>
			</Card>



			<SubHead
				title='Olvasnivaló:'
				tools={<DocuCreate affairKey={affairKey} />}
			/>
			{state.isFirstLoaded && <DocuList affairKey={affairKey} initialList={docus} />}


			<AvatarBox parent={affair} variant="fixed" />
		</VStack>}

	</Box>

}