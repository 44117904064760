import { Box, Switch, useBoolean } from "@chakra-ui/react";
import { IDeepTopicRef } from "@dvapi/affair.int";
import { DEEP_SERVICE_WORKER } from "../serviceWorkerRegistration";

export interface DeepSubscribeNotificationsProps {
	topicRef: IDeepTopicRef;
}

export function DeepSubscribeNotifications({ }: DeepSubscribeNotificationsProps) {

	const [isChecked, setIsChecked] = useBoolean(Notification.permission === 'granted');

	const onSwitchChange = async (checked: boolean) => {
		if (!checked) {

			// TODO: unsubscribe....

			setIsChecked.off();
			return;
		}


		// ---------------------------------- step 1 : allow notification ------------------------
		const perm = Notification.permission;
		if (perm === 'denied') {
			alert("Letiltottad az üzenetküldést! Kérlek engedélyezed a böngészőben!");
			return;
		} else if (perm === 'default') {
			try {
				setIsChecked.on();
				await askNotificationPermission();
			} catch (ex) {
				setIsChecked.off();
				return;
			}
		}

		// ---------------------------------- step 2 : get push subscription ------------------------
		try {
			const sub = await pushSubscription();
			console.log('----> sub ---->', sub.endpoint)
		} catch(ex) {
			setIsChecked.off();
			return;
		}


	}

	// ===========================================================================
	// ----------------------------- Notification --------------------------------
	// ===========================================================================
	function askNotificationPermission() {
		return new Promise(function (resolve, reject) {
			const permissionResult = Notification.requestPermission(function (result) {
				resolve(result);
			});

			if (permissionResult) {
				permissionResult.then(resolve, reject);
			}
		}).then(function (permissionResult) {
			if (permissionResult !== 'granted') {
				throw new Error("We weren't granted permission.");
			}
		});
	}

	// ===========================================================================
	// ----------------------------- Push Subscription ---------------------------
	// ===========================================================================
	const pushSubscription = () => {
		return new Promise((resolve : (sub : PushSubscription) => void, reject) => {
			if (!DEEP_SERVICE_WORKER.registration) {
				reject('No service worker "registration" found.');
			};

			DEEP_SERVICE_WORKER.registration!.pushManager.getSubscription().then(function (sub) {
				if (sub === null) {
					// Update UI to ask user to register for Push
					console.log('Not subscribed to push service!');

					DEEP_SERVICE_WORKER.registration!.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: urlBase64ToUint8Array(
							process.env.REACT_APP_PUSH_PUBLIC_KEY!
						),
					}).then(function (sub) {
						// We have a subscription, update the database
						console.log('Endpoint URL: ', sub.endpoint);
						resolve(sub);

					}).catch(function (e) {

						if (Notification.permission === 'denied') {
							reject('Permission for notifications was denied');
						} else {
							console.error('Unable to subscribe to push', e);
							reject('Unable to subscribe to push');
						}
					});
				} else {
					// We have a subscription, update the database
					console.log('Subscription object: ', sub);
					resolve(sub);
				}
			})
		});
	}


	return <Box>
		<Switch
			isChecked={isChecked}
			onChange={e => onSwitchChange(e.target.checked)}
		/> Értesítések ({Notification.permission})
	</Box>
}



function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
	console.log('--- megint? ----', base64);
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

