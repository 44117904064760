import { Box, Link as ChakraLink, IconButton, ListItem, Portal, UnorderedList, useBoolean } from "@chakra-ui/react";
import { ITextListRefPub } from "@dvapi/textlist.int";
import { ITextListShowChange, TextListChanges } from "./TextListChanges";
import { MdEdit } from "react-icons/md";
import { TextListEdit } from "./TextListEdit";
import { useContext, useEffect, useState } from "react";
import { DeepLayoutCtx } from "../../components/misc";

export interface TextListProps {
	textListRef: ITextListRefPub;
}

export function TextListRef({ textListRef: origTextListRef }: TextListProps) {

	const [showChanges, setShowChanges] = useBoolean();
	const [isEdited, setIsEdited] = useBoolean();
	const layoutCtx = useContext(DeepLayoutCtx);

	const [textListRef, setTextListRef] = useState<ITextListRefPub>(origTextListRef);
	const onChangeCreated = (newTextList: ITextListRefPub) => {
		setTextListRef(newTextList);
		setIsEdited.off();
		setChangeToShow(undefined);
	}
	useEffect(() => {
		setTextListRef(origTextListRef);
	}, [origTextListRef]);

	const [changeToShow, setChangeToShow] = useState<ITextListShowChange>();
	const onChangeSelect = (change: ITextListShowChange) => {
		setChangeToShow(change);
	}

	const openChanges = () => {
		if (showChanges) {
			setShowChanges.off();
			setChangeToShow(undefined);
		} else {
			setShowChanges.on();
		}
	}

	return <Box position='relative'>
		{!isEdited && <>
			{!changeToShow
				? <>
					<UnorderedList>
						{textListRef.items.map(item => <ListItem key={item.liKey}>{item.text}</ListItem>)}
					</UnorderedList>
				</>
				: <>
					<UnorderedList>
						{changeToShow.items.map((item, pix) => <ListItem key={pix}
							bgColor={item.isModified || item.isDeleted ? '#ffff8080' : ''}
						>
							{(item.isDeleted || item.isModified) && <Box textDecor='line-through' >{item.origText}</Box>}
							{!item.isDeleted && item.text}
						</ListItem>)}
					</UnorderedList>

				</>}
		</>}

		{isEdited && <TextListEdit origTextList={textListRef} onChangeCreated={onChangeCreated} onClose={() => setIsEdited.off()} />}

		{!!textListRef.pendingChanges && <ChakraLink
			mt={2} display='inline-block' opacity={.5} color='red'
			onClick={openChanges}
		>Várakozó változtatások: {textListRef.pendingChanges}</ChakraLink>}

		{showChanges && <Portal containerRef={{ current: layoutCtx.editorElem }}>
			<TextListChanges
				textListKey={textListRef.textListKey}
				onChangeSelect={onChangeSelect}
				onChangeCreated={onChangeCreated}
				onClose={() => setShowChanges.off()}

			/>
		</Portal>}
		{!isEdited && !showChanges &&
			<Box position='absolute' right={0} top={0}>
				<IconButton aria-label="szerkesztés" ml='auto' variant='link' size='sm' icon={<MdEdit />}
					onClick={() => setIsEdited.on()}
				/>

			</Box>}
	</Box>
}