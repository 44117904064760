import { Box, Link as ChakraLink, ChakraProvider, Img, useColorModeValue } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { Link as ReactRouterLink, Route, Routes } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { DeepLayoutCtx, IDeepLayoutCtx } from "./components/misc";
import { useDeepApi } from "./components/utils";
import { Affair } from "./features/affair/Affair";
import { DeepAuthContext } from "./features/auth/DeepAuth";
import { SignIn } from "./features/auth/SignIn";
import { Dashboard } from "./features/layout/Dashboard";
import { deepTheme } from "./theme";
import { AffairCreate } from "./features/affair/AffairCreate";


export const App = () => {

	const authCtx = useContext(DeepAuthContext);
	const api = useDeepApi();
	const bg = useColorModeValue('#f0e274', 'red') //'rgb(196,159,68)'

	const setEditorRef = (editorElem: HTMLDivElement | null) => {
		if (editorElem != layoutCtx.editorElem) {
			setLayoutCtx({
				editorElem
			});
		}
	}
	const [layoutCtx, setLayoutCtx] = useState<IDeepLayoutCtx>({
		editorElem: null
	})


	return <ChakraProvider theme={deepTheme} toastOptions={{ defaultOptions: { position: "bottom-right" } }}>
		<DeepLayoutCtx.Provider value={layoutCtx}>

			<Box className='dv-header' position='fixed' top={0} left={0} right={0} zIndex={1}
				p={2} display='flex' flexDir='row' alignItems='center' bg={bg} color='#000'>
				<ChakraLink as={ReactRouterLink} to={`/`}>
					<Img src={'/brand1.png'} h='24px' />
				</ChakraLink>
				<Box ml='auto' display='flex' flexDir='row' alignItems='center'>
					<SignIn />
					<ColorModeSwitcher size='xs' />
				</Box>
			</Box>
			<Box className='dv-header-placeholder' h='40px'></Box>

			<Box className='dv-layout-twocols' display='flex' flexDir='row' justifyContent='center' gap={5}
				transition='flex-basis 500ms ease-in-out'
			>
				<Box className='dv-layout-main' transition='flex-basis 500ms ease-in-out'>
					<Routes>
						<Route index element={<Dashboard />} />
						<Route path="affair/:affairKey" element={<Affair />} />
						<Route path="affair-create" element={<AffairCreate />} />
					</Routes>
				</Box >
				<Box border='0px solid red'  width='20em'
					transition='width 500ms ease-in-out'>
					<Box position='fixed' top='60px'>
						<Box ref={(ref) => setEditorRef(ref)}>

						</Box>
					</Box>
				</Box>
			</Box>
		</DeepLayoutCtx.Provider>
	</ChakraProvider >

}
