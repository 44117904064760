import { useBoolean, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DeepAuthContext } from "../features/auth/DeepAuth";
import { IDeepApi } from "@dvapi/api.int";

export function generateKey() {
	return '' + (new Date().getTime());
}

const API_BASE = 'https://api.deepvoter.com/v1';

export function useDeepAuth() {
	return useContext(DeepAuthContext);
}

export function useDeepApi() {
	const authCtx = useContext(DeepAuthContext);
	const toast = useToast();

	// ---------------------------------------
	const apiFetch = useCallback(async function <TApi extends IDeepApi>(req: TApi['request'], init?: RequestInit): Promise<TApi['response']['payload']> {
		if (req.params) {
			const arr = req.url.split('/');
			for (let key of Object.keys(req.params)) {
				const ix = arr.indexOf(':' + key);
				if (ix < 0)
					throw `Param not found in fetch: ${key}`;
				arr[ix] = req.params[key];
			}
			req.url = arr.join('/');
		}
		const url = API_BASE + (req.url.startsWith('/') ? '' : '/') + req.url;

		//input = 'https://hcgb110woh.execute-api.eu-central-1.amazonaws.com/prod' + (input.startsWith('/') ? '' : '/') + input;
		init = Object.assign({}, init, {});

		init.headers = Object.assign({}, init.headers
			, {
				'X-Dv-Token': //'hello'
					btoa(JSON.stringify({ user: authCtx.user, userSigned: authCtx.userSigned, }))
			}
		);
		init.method = req.method;
		if (req.payload)
			init.body = JSON.stringify(req.payload);



		const response = await fetch(url, init);
		let resp: any = {};
		let jsonError: any;
		try {
			resp = await response.json();
		} catch (ex) {
			jsonError = ex;
		}
		if (response.ok) {
			if (jsonError)
				throw jsonError;
			return resp;
		}
		if (resp?.publicMessage)
			throw response.status + ' | ' + resp.publicMessage;
		if (resp?.error) {
			throw response.status + ' | ' + resp.error;
		} else {
			throw response.status + ' | ' + response.statusText;
		}
	}, [authCtx]);



	const apiFetchToast = useCallback(async function <TApi extends IDeepApi>(req: TApi['request'], init?: RequestInit): Promise<TApi['response']['payload']> {
		console.log('toast....')
		let responsePromise = apiFetch<TApi>(req, init);
		toast.promise(responsePromise, {
			success: { title: 'Sikerült' },
			error: (ex) => ({ title: 'Hiba', description: "" + ex }),
			loading: { title: 'Dolgozunk...' },
		})

		return await responsePromise.then();
	}, [toast, apiFetch]);




	return useMemo(() => ({
		fetch: apiFetch,
		fetchToast: apiFetchToast,
	}), [apiFetch, apiFetchToast]);
}

// ===============================================================================
export interface IDeepEndpointState {
	isLoading: boolean,
	isFirstLoaded: boolean,
	error?: string,
}

export function useDeepEndpoint<TApi extends IDeepApi>(url: TApi['request']['url'], params: TApi['request']['params']
	, callback: (
		data: TApi['response']['payload']
	) => void
	, init?: RequestInit): IDeepEndpointState {

	const api = useDeepApi();

	//------ should be function ------
	let fullUrl = url;
	if (params) {
		const arr = fullUrl.split('/');
		for (let key of Object.keys(params)) {
			const ix = arr.indexOf(':' + key);
			if (ix < 0)
				throw `Param not found in fetch: ${key}`;
			arr[ix] = params[key];
		}
		fullUrl = arr.join('/');
	}
	fullUrl = API_BASE + (fullUrl.startsWith('/') ? '' : '/') + fullUrl;
	//------ should be function ------


	const [state, setState] = useState<IDeepEndpointState>({
		isLoading: true,
		isFirstLoaded: false,

	});

	useEffect(() => {
		if ((window as any).dvPreload && (window as any).dvPreload[fullUrl]) {
			callback((window as any).dvPreload[fullUrl].data);
			setState({
				isFirstLoaded: true,
				isLoading: false,
			})
		}

		(async () => {
			try {
				const resp = await api.fetch<TApi>({
					url, method: 'GET', params
				});
				callback(resp);
				setState({
					isFirstLoaded: true,
					isLoading: false,
				});
			} catch (ex) {
				setState({
					isFirstLoaded: false,
					isLoading: false,
					error : ''+ex
				});
			}
		})();



	}, [fullUrl]);

	return state;

}

