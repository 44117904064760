import { Button, ButtonProps, CircularProgress } from "@chakra-ui/react";


export interface RemoteButtonProps extends ButtonProps {
	isLoading: boolean;
}

export function RemoteButton({ isDisabled, isLoading, ...rest }: RemoteButtonProps) {
	return <Button {...rest}
		rightIcon={isLoading ? <CircularProgress size='1em' isIndeterminate /> : undefined}
		isDisabled={isDisabled || isLoading}
	/>
}