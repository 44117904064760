import { Box, Button, IconButton, Tooltip } from "@chakra-ui/react";
import { ChangeEvent, useRef, useState } from "react";
import { useDeepApi, useDeepAuth } from "../../components/utils";
import { IAffairDocuPostApi } from "@dvapi/affair.int";
import { MdFileUpload } from "react-icons/md";



export function DocuCreate({ affairKey }: { affairKey: string }) {

    const MAX_IMAGE_SIZE = 1000000;
    const authCtx = useDeepAuth();
    const api = useDeepApi();
    const [isLoading, setIsLoading] = useState(false);

    const fileRef = useRef<HTMLInputElement>(null);




    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files || [];
        if (!files.length)
            return
        let reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (!e.target?.result || typeof e.target.result !== 'string')
                return console.log('???')

            console.log('length: ', e.target.result.includes('data:image/jpeg'))
            if (!e.target.result.includes('data:image/jpeg')) {
                return alert('Wrong file type - JPG only.')
            }
            if (e.target.result.length > MAX_IMAGE_SIZE) {
                return alert('Image is loo large.')
            }
            uploadImage(e.target.result);
        }
        reader.readAsDataURL(files[0]);
    }

    const uploadImage = async (image: string) => {

        setIsLoading(true);
        try {
            const resp = await api.fetchToast<IAffairDocuPostApi>({ url: "/affair/:affairKey/docu", method: 'POST', params: { affairKey } });
            console.log('----->', resp);

            let binary = atob(image.split(',')[1]);
            let array = []
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }
            let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
            console.log('Uploading to: ', resp.uploadURL)
            const result = await fetch(resp.uploadURL, {
                method: 'PUT',
                body: blobData
            })
            console.log('Result: ', result)
            // Final URL for the user doesn't need the query string params
            console.log('uploadURL', resp.uploadURL.split('?')[0]);



        } catch (ex) { } finally {
            setIsLoading(false);
        }
    }


    if (!authCtx.user)
        return <></>;

    return <>
        <Tooltip label='Új dokumentum feltöltése'>
            <Button aria-label="feltöltés" leftIcon={<MdFileUpload />} size='xs' colorScheme="yellow" variant='ghost'
                onClick={() => fileRef.current?.click()}
            >
                Feltöltés
            </Button>
        </Tooltip>
        <Box display='none'><input type="file" onChange={onFileChange} accept="image/jpeg" ref={fileRef} /></Box>
    </>
}