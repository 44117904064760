import { Box, useToast } from "@chakra-ui/react";
import { ISignInInfo } from "@dvapi/auth.int";
import { createContext, useCallback, useMemo, useState } from "react";


export interface IDeepAuthContext extends Partial<ISignInInfo> {
	onSignIn: (info: ISignInInfo) => void,
	onSignOut: () => void,
}
export const DeepAuthContext = createContext<IDeepAuthContext>({
	onSignIn: () => { throw `no auth ctx` },
	onSignOut: () => { throw `no auth ctx` },
})

export interface DeepAuthProps {
	children: React.ReactNode;
}

export function DeepAuth({ children }: DeepAuthProps) {

	const toast = useToast();

	const [signInInfo, setSignInInfo] = useState<ISignInInfo | null>(() => {
		const infoStr = window.localStorage.getItem('signin-info');
		if (!infoStr)
			return null;
		try {
			const info = JSON.parse(infoStr);
			//console.log('signin useeff: ', info);
			return info;
		} catch (ex) {
			toast({
				title: 'Hibás bejelentkezési adatok.',
				description: '' + ex,
				status: 'error',
			});
			return null;
		}

	});
	const onSignIn = useCallback((info: ISignInInfo) => {
		setSignInInfo(info);
		window.localStorage.setItem('signin-info', JSON.stringify(info));
	}, []);
	const onSignOut = useCallback(() => {
		setSignInInfo(null);
		window.localStorage.removeItem('signin-info');
	}, []);
	const ctx = useMemo<IDeepAuthContext>(() => {
		return {
			user: signInInfo?.user,
			userSigned: signInInfo?.userSigned,
			onSignIn, onSignOut,
		}
	}, [onSignIn, onSignOut, signInInfo]);

	return <DeepAuthContext.Provider value={ctx} >
		{children}
	</DeepAuthContext.Provider>
}



export function SignInRequired() {
    return <Box>Bejelentkezés szükésges!</Box>
}