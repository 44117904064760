import { Box, BoxProps, Tooltip, TooltipProps, useColorModeValue } from "@chakra-ui/react";
import { IAffairJoinRef, TUserAffairRel, TUserCounts } from "@dvapi/affair.int";
import { useEffect, useState } from "react";


export interface AvatarBoxProps extends BoxProps {
    variant: 'fixed' | 'mini';
    parent: {
        userCounts: TUserCounts;
        topUsers: Record<string, IAffairJoinRef>;
    }
}


export interface IAvatar {
    userKey?: string;
    userName?: string;
    color: string;
    rel: TUserAffairRel;
    x: number;
    y: number;
}


export function AvatarBox({ parent, variant, ...rest }: AvatarBoxProps) {

    const [avatars, setAvatars] = useState<IAvatar[]>([]);

    const maxX = 90;
    useEffect(() => {

        const counts : Record<TUserAffairRel, number> = parent.userCounts;

        let li = new Array<IAvatar>();
        for (let user of Object.values(parent?.topUsers || {})) {
            li.push({
                userKey: user.userRef.userKey,
                userName: user.userRef.userName,
                color: 'purple',
                rel: user.rel,
                x: Math.floor(Math.random() * maxX),
                y: 30
            })
            counts[user.rel]--;
        }

        for (let i = 0; i < counts['support']; i++) {
            li.push({
                color: '#888',
                rel: 'support',
                x: Math.floor(Math.random() * maxX),
                y: 0
            })
        }
        for (let i = 0; i < counts['activist']; i++) {
            li.push({
                color: '#000',
                rel: 'activist',
                x: Math.floor(Math.random() * maxX),
                y: 10
            })
        }
        for (let i = 0; i < counts['engaged']; i++) {
            li.push({
                color: 'deepskyblue',
                rel: 'engaged',
                x: Math.floor(Math.random() * maxX),
                y: 20
            })
        }

        li.sort((a,b) => a.y-b.y);
        setAvatars(li);
    }, [parent]);

    const kontur = useColorModeValue('#0008', '#fff4');

    return <Box
        width='100%'
        
        display='flex' flexDir='row' alignItems='flex-end'
        {...variant === 'fixed' && {
            position: 'fixed',
            bottom: 0, left: 0, right: 0, zIndex: 2,
            justifyContent: 'center',
            height: '100px',
            bg:'linear-gradient(0deg, #8887 40%, transparent 50%)',
        }}
        {...variant === 'mini' && {
            position: 'relative',
            justifyContent: 'flex-start',
            mt: 3,
            height: '36px', mr: '30px',
            bg: 'linear-gradient(0deg, #8884 25%, transparent 50%)',
        }}

    >
        {avatars.map((avatar, ix) => <Box key={ix}
            pb={1}>
            <TooltipIf label={avatar.userName ? `${avatar.userName} - ${avatar.rel}` : ''} >
                <Box
                    position='absolute'
                    left={avatar.x+'%'}
                    top={(avatar.y * (variant==='mini'?.3:1))+'px'}
                >

                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        fill={avatar.color}
                        stroke={kontur} strokeWidth='4'
                        {...variant === 'fixed' && {
                            width: '64px', height: '64px'
                        }}
                        {...variant === 'mini' && {
                            width: '24px', height: '24px'
                        }}
                        viewBox="0 0 125.2 125.2"
                    >
                        <g>
                            <path d="M52.65,125.2h19.9c3.3,0,6-2.7,6-6V86.301h3.399c3.301,0,6-2.701,6-6V43.2c0-3.3-2.699-6-6-6H43.25c-3.3,0-6,2.7-6,6
v37.101c0,3.299,2.7,6,6,6h3.4V119.2C46.65,122.5,49.25,125.2,52.65,125.2z"/>
                            <circle cx="62.55" cy="15.7" r="15.7" />
                        </g>
                    </svg>
                </Box>
            </TooltipIf>
        </Box>)}

    </Box>


}

export function TooltipIf({label, children, ...rest} : TooltipProps) {
    if (label) {
        return <Tooltip label={label} {...rest} >{children}</Tooltip>
    } else {
        return <>{children}</>;
    }
}