import { Alert, AlertDescription, AlertIcon, Button, FormControl, FormHelperText, FormLabel, Icon, Input, LightMode, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useBoolean, useDisclosure } from "@chakra-ui/react";
import { ISignInApi, ISignInInfo } from "@dvapi/auth.int";
import { useContext, useState } from "react";
import { MdExpandMore, MdPerson } from 'react-icons/md';
import { RemoteButton } from "../../components/RemoteButton";
import { useDeepApi } from "../../components/utils";
import { DeepAuthContext } from "./DeepAuth";
import { SignUp } from "./SignUp";
import { DeepErrorBox } from "../../components/misc";

export type TSignStage = 'signin' | 'signup';

export function SignIn() {
	const authCtx = useContext(DeepAuthContext);
	const api = useDeepApi();

	const [stage, setStage] = useState<TSignStage>('signin');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = useBoolean();
	const [error, setError] = useState('');

	const usrSubmitSignIn = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const target = e.target as typeof e.target & {
			email: { value: string };
			password: { value: string };
		};
		const email = target.email.value;
		const password = target.password.value;
		setIsLoading.on();
		setError('');
		try {
			///debugger;
			const resp = await api.fetch<ISignInApi>({
				url: '/auth/signin',
				method: 'POST',
				payload: {
					email, password
				}
			});
			authCtx.onSignIn(resp);
			onClose();
		} catch (ex) {
			setError('' + ex);
		} finally {
			setIsLoading.off();
		}
	}



	return (
		<>

			{!authCtx.user
				? <LightMode><Button aria-label="bejelentkezés" size='xs' onClick={onOpen}
					leftIcon={<MdPerson />}
				>Belépek</Button>
				</LightMode>
				: <Menu >
					<LightMode>
						<MenuButton as={Button} size='xs' variant='ghost'
							leftIcon={<Icon as={MdPerson} />}
							rightIcon={<Icon as={MdExpandMore} />}>
							{authCtx.user.userName}
						</MenuButton>
					</LightMode>
					<MenuList>
						<MenuItem onClick={authCtx.onSignOut} >Kilépek</MenuItem>
					</MenuList>
				</Menu>
			}



			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalCloseButton />
				{stage === 'signin' &&
					<form onSubmit={usrSubmitSignIn}>
						<ModalContent>
							<ModalHeader>Belépés</ModalHeader>
							<ModalBody>
								<DeepErrorBox error={error} />
								<fieldset disabled={isLoading}>
									<FormControl>
										<FormLabel>Email cím</FormLabel>
										<Input type='email' autoComplete="username" name='email' />
										<FormHelperText>&nbsp;</FormHelperText>
									</FormControl>
									<FormControl>
										<FormLabel>Jelszó</FormLabel>
										<Input type='password' autoComplete="current-password" name='password' />
										<FormHelperText>&nbsp;</FormHelperText>
									</FormControl>
								</fieldset>
							</ModalBody>

							<ModalFooter>
								<Button onClick={() => setStage('signup')} variant='ghost'>Regisztráció</Button>
								<RemoteButton type='submit' isLoading={isLoading} colorScheme='blue' ml={3} >
									Belépés
								</RemoteButton>
							</ModalFooter>
						</ModalContent>
					</form>
				}
				{stage === 'signup' && <SignUp onSignIn={() => setStage('signin')} onClose={onClose} />}
			</Modal>
		</>
	)





}





